<template>
  <v-container>
    <p class="body-1 text-center">
      Page not found.
    </p>
  </v-container>
</template>

<script>
export default {
  name: 'CheckInDumpReport'
}
</script>
